<template>
  <div
    class="mobile-menu"
    @click="$emit('close')"
  >
      <div
          @click.stop=""
          class="mobile-menu__wrapper"
      >
        <div class="mobile-menu__top">
          <a href="/" class="logo"></a>
          <span
            @click="$emit('close')"
            class="mobile-menu__close"
          ></span>
        </div>
        <div class="mobile-menu__content">
            <p>
                <a href="tel:+74951337563" class="mobile-menu__phone">
                    8 (495) 133 75 63
                </a>
            </p>
            <router-link v-if="!isAuth" to="/login" class="mobile-menu__status">Войти</router-link>
            <a v-else href="/lk" class="mobile-menu__status">{{ fullName }}</a>
        </div>
      </div>
  </div>
</template>

<script>
import './mobile-menu.scss';
import { mapGetters } from 'vuex'

export default {
  name: 'MobileMenu',
  data() {
    return {
      menu: [
        {
          name: "Как получить",
          anchor: "how-get"
        },
        {
          name: "Условия",
          anchor: "conditions"
        },
        {
          name: "Вопрос/ответ",
          anchor: "faq"
        }
      ],
        calculator: 10000
    }
  },
    watch: {
      '$route'() {
          this.$emit('close')
      }
    },
  computed: {
      ...mapGetters({
          user: 'application/user'
      }),
      isAuth() {
          return !!this.user.contactData.id
      },
      fullName() {
          return [this.user.contactData?.firstname, this.user.contactData?.lastname].join(' ').trim() || 'Личный кабинет'
      }
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },
  beforeCreate() {
    document.body.style.overflow = 'hidden';
  }
};
</script>
